import { useState, useEffect } from 'react';
import { 
  isValidEmail, 
  isValidDocument, 
  isValidMD5, 
  isValidSHA1,
  validateCPF,
  validateCNPJ
} from '../utils/validators';

export const useFormValidation = (certification) => {
  const [errors, setErrors] = useState({});

  const validateAllFields = () => {
    const newErrors = {};

    // Request Information validation
    if (!certification.data?.requester?.fullName) newErrors['requester.fullName'] = 'Required';
    if (!certification.data?.requester?.email) newErrors['requester.email'] = 'Required';
    else if (!isValidEmail(certification.data.requester.email)) newErrors['requester.email'] = 'Invalid email address';
    if (!certification.data?.requester?.phone) newErrors['requester.phone'] = 'Required';
    if (!certification.data?.date) {
      newErrors.date = 'Required';
    } else if (isNaN(new Date(certification.data.date.seconds * 1000).getTime())) {
      newErrors.date = 'Invalid date';
    }

    // Enhanced owners validation
    if (!certification.data?.owners?.length) {
      newErrors.owners = 'At least one owner is required';
    } else {
      certification.data.owners.forEach((owner, index) => {
        if (!owner.fullName) {
          newErrors[`owners.${index}.fullName`] = 'Required';
        }
        if (!owner.docId) {
          newErrors[`owners.${index}.docId`] = 'Required';
        } else {
          const cleanDoc = owner.docId.replace(/\D/g, '');
          if (owner.docType === 'CPF') {
            if (!validateCPF(cleanDoc)) {
              newErrors[`owners.${index}.docId`] = 'Invalid CPF';
            }
          } else if (owner.docType === 'CNPJ') {
            if (!validateCNPJ(cleanDoc)) {
              newErrors[`owners.${index}.docId`] = 'Invalid CNPJ';
            }
          } else {
            newErrors[`owners.${index}.docId`] = 'Invalid document format';
          }
        }
      });
    }
    
    // Property validation
    if (!certification.data?.car) newErrors.car = 'Required';
    if (!certification.data?.matriculation?.[0]) newErrors.matriculation = 'At least one matriculation should be added.';
    if (!certification.data?.propertyArea) newErrors.propertyArea = 'Required';
    if (!certification.data?.address?.propertyName) newErrors['address.propertyName'] = 'Required';
    if (!certification.data?.address?.city) newErrors['address.city'] = 'Required';
    if (!certification.data?.address?.state) newErrors['address.state'] = 'Required';
    if (!certification.data?.address?.country) newErrors['address.country'] = 'Required';
    if (!certification.data?.address?.latitude) newErrors['address.latitude'] = 'Required';
    if (!certification.data?.address?.longitude) newErrors['address.longitude'] = 'Required';
    
    // Carbon information validation
    if (!certification.data?.carbonCredit) newErrors.carbonCredit = 'Required';
    if (!certification.data?.baseYear?.startDate) {
      newErrors['baseYear.startDate'] = 'Required';
    } else if (isNaN(new Date(certification.data.baseYear.startDate.seconds * 1000).getTime())) {
      newErrors['baseYear.startDate'] = 'Invalid date';
    }
    
    if (!certification.data?.baseYear?.endDate) {
      newErrors['baseYear.endDate'] = 'Required';  
    } else if (isNaN(new Date(certification.data.baseYear.endDate.seconds * 1000).getTime())) {
      newErrors['baseYear.endDate'] = 'Invalid date';
    }
    
    // Validate base year dates
    if (certification.data?.baseYear?.startDate && certification.data?.baseYear?.endDate) {
      const startDate = new Date(certification.data.baseYear.startDate.seconds * 1000);
      const endDate = new Date(certification.data.baseYear.endDate.seconds * 1000);
      if (endDate <= startDate) {
        newErrors['baseYear.endDate'] = 'End date must be after start date.';
      }
    }
    
    // Registration information validation
    if (!certification.data?.registrationDate) {
      newErrors.registrationDate = 'Required';
    } else if (isNaN(new Date(certification.data.registrationDate.seconds * 1000).getTime())) {
      newErrors.registrationDate = 'Invalid date';
    }
    
    // Validate registration date against base year
    if (certification.data?.registrationDate && certification.data?.baseYear?.endDate) {
      const regDate = new Date(certification.data.registrationDate.seconds * 1000);
      const endDate = new Date(certification.data.baseYear.endDate.seconds * 1000);
      if (regDate <= endDate) {
        newErrors.registrationDate = 'Registration date must be after base year end.';
      }
    }

    // Only validate these fields for new records
    if (!certification.id) {
      if (!certification.data?.userKey) {
        newErrors.userKey = 'Required';
      } else if (!isValidSHA1(certification.data.userKey)) {
        newErrors.userKey = 'Invalid SHA1 hash';
      }

      if (!certification.data?.id && !certification.data?.certificationKey) {
        newErrors.certificationKey = 'Required';
      } else if (!isValidMD5(certification.data.certificationKey)) {
        newErrors.certificationKey = 'Invalid MD5 hash';
      }

      if (!certification.data?.creationCode) {
        newErrors.creationCode = 'Required';
      } else if (!isValidMD5(certification.data.creationCode)) {
        newErrors.creationCode = 'Invalid MD5 hash';
      }
    }

    // Add compensation validation
    if (certification.data?.compensations?.length) {
      const compensationErrors = validateCompensations(certification.data.compensations);
      Object.assign(newErrors, compensationErrors);
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validate = (field, value) => {    
    const newErrors = { ...errors };
    delete newErrors[field];

    switch (field) {
      case 'requester.email':
        if (!value) newErrors['requester.email'] = 'Required';
        else if (!isValidEmail(value)) newErrors['requester.email'] = 'Invalid email address';
        break;
      case 'owners.docId':
        if (!value) {
          newErrors.docId = 'Required';
        } else {
          const cleanDoc = value.replace(/\D/g, '');
          const docType = field.includes('CPF') ? 'CPF' : 'CNPJ';
          if (!isValidDocument(cleanDoc, docType)) {
            newErrors.docId = `Invalid ${docType}`;
          }
        }
        break;
      case 'currentMatriculation':
        if (!certification.data?.matriculation?.[0]) {
          delete newErrors['matriculation'];
        }
      case 'matriculation':
        if (Array.isArray(value) && !value?.[0]) newErrors.matriculation = 'At least one matriculation should be added.';
        break;
      case 'carbonCredit':
        if (!value) newErrors.carbonCredit = 'Required';
        break;
      case 'baseYear.startDate':
        if (!value) newErrors.baseYearStart = 'Required';
        break;
      case 'baseYear.endDate':
        if (!value) newErrors.baseYearEnd = 'Required';
        break;
      case 'registrationDate':
        if (!value) newErrors.registrationDate = 'Required';
        break;
      case 'userKey':
        if (!value) newErrors.userKey = 'Required';
        else if (!isValidSHA1(value)) newErrors.userKey = 'Invalid SHA1 hash';
        break;
      case 'id':
      case 'certificationKey':
        if (!value || value === '') newErrors.certificationKey = 'Required';
        else if (!isValidMD5(value)) newErrors.certificationKey = 'Invalid MD5 hash';
        break;
      case 'creationCode':
        if (!value) newErrors.creationCode = 'Required';
        else if (!isValidMD5(value)) newErrors.creationCode = 'Invalid MD5 hash';
        break;
      case 'compensations':
        if (Array.isArray(value)) {
          const compensationErrors = validateCompensations(value);
          Object.assign(newErrors, compensationErrors);
        }
        break;
      default:
        if (!value || value === '') newErrors[field] = 'Required';
        break;
    }

    setErrors(newErrors);
  };

  return { errors, setErrors, validateAllFields, validate };
}; 

// Validate compensations if they exist
const validateCompensations = (compensations) => {
  const errors = {};
  if (compensations?.length) {
    compensations.forEach((compensation, index) => {
      if (!compensation.description) {
        errors[`compensations.${index}.description`] = 'Required';
      }
      if (!compensation.offset) {
        errors[`compensations.${index}.offset`] = 'Required';
      }
      if (!compensation.buyer.fullName) {
        errors[`compensations.${index}.buyer.fullName`] = 'Required';
      }
      if (!compensation.buyer?.docId) {
        errors[`compensations.${index}.buyer.docId`] = 'Required';
      } else {
        const cleanDoc = compensation.buyer.docId.replace(/\D/g, '');
        if (compensation.buyer.docType === 'CPF') {
          if (!validateCPF(cleanDoc)) {
            errors[`compensations.${index}.buyer.docId`] = 'Invalid CPF';
          }
        } else if (compensation.buyer.docType === 'CNPJ') {
          if (!validateCNPJ(cleanDoc)) {
            errors[`compensations.${index}.buyer.docId`] = 'Invalid CNPJ';
          }
        } else {
          errors[`compensations.${index}.buyer.docId`] = 'Invalid document format';
        }
      }
    });
  }
  return errors;
};