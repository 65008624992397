import React from 'react';
import { Grid } from '@mui/material';
import TextField from './FormFields/TextField';

const AddressFields = ({
  address,
  handleFieldChange,
  errors,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          required
          label="City"
          value={address?.city}
          onChange={(value) => handleFieldChange('address.city', value)}
          error={errors['address.city']}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          required
          label="State"
          value={address?.state}
          onChange={(value) => handleFieldChange('address.state', value)}
          error={errors['address.state']}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          required
          label="Country"
          value={address?.country}
          onChange={(value) => handleFieldChange('address.country', value)}
          error={errors['address.country']}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          required
          label="Latitude"
          value={address?.latitude}
          onChange={(value) => handleFieldChange('address.latitude', value)}
          error={errors['address.latitude']}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          required
          label="Longitude"
          value={address?.longitude}
          onChange={(value) => handleFieldChange('address.longitude', value)}
          error={errors['address.longitude']}
        />
      </Grid>
    </Grid>
  );
};

export default AddressFields; 