import React, {useCallback, useRef, useState} from 'react';
import {Container, Spinner} from 'react-bootstrap';
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {make3dTransformValue} from "react-quick-pinch-zoom";
import {toast, ToastContainer} from "react-toastify";
import {isCNPJ, isCPF} from "brazilian-values";
import "./search-form.scss";
import { compensationApi } from "../../../config/firebase";
import {Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Typography, TextField} from "@mui/material";
import {formatDate, formatDocId} from "../../../utils/utils";

function CompensationSearchForm() {
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [document, setDocument] = useState('');
  const [documentType, setDocumentType] = useState('CPF');
  const [documentError, setDocumentError] = useState('');
  const [documentTouched, setDocumentTouched] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [compensations, setCompensations] = useState(null);

  const formRef = useRef(null);
  const documentInputRef = useRef(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (!documentInputRef.current) return;

    const docValue = documentInputRef.current.value;
    const isDocumentValid = validateDocument(docValue);
    
    if (!isDocumentValid) {
      setValidated(true);
    } else {
      const docId = docValue.replace(/\D/g, ''); // Remove non-digit characters
      fetchCompensations(documentType, docValue);
    }
  };

  async function fetchCompensations(docType, docId) {
    setIsLoading(true);
    try {
      const response = await compensationApi.get({ docType, docId });
      if (response.data && response.data.data.length > 0) {
        setCompensations(response.data.data);
        setOpenDialog(true);
      } else {
        toast.info('Nenhuma compensação encontrada para o documento fornecido.', {
          position: toast.POSITION.BOTTOM_CENTER
        });
      }
    } catch (error) {
      console.error('Error fetching compensations:', error);
      if (error.code === 'functions/not-found') {
        toast.info('Nenhuma compensação encontrada para o documento fornecido.', {
          position: toast.POSITION.BOTTOM_CENTER
        });
      } else if (error.code === 'functions/unauthenticated') {
        toast.error('Você precisa estar logado para consultar compensações.', {
          position: toast.POSITION.BOTTOM_CENTER
        });
      } else {
        toast.error('Erro ao buscar compensações: ' + error.message, {
          position: toast.POSITION.BOTTOM_CENTER
        });
      }
    } finally {
      setIsLoading(false);
      setValidated(false);
    }
  };

  const handleDocumentChange = (event) => {
    const docValue = event.target.value;
    const cleanValue = docValue.replace(/\D/g, '');
    let formattedValue = docValue;

    if (documentType === 'CPF') {
      if (cleanValue.length <= 11) {
        formattedValue = cleanValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      }
    } else {
      if (cleanValue.length <= 14) {
        formattedValue = cleanValue.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
      }
    }

    setDocument(formattedValue);
    setDocumentTouched(true);
    validateDocument(formattedValue);
  };

  const handleTypeChange = (event) => {
    setDocumentType(event.target.value);
    setDocument('');
    setDocumentError('');
    setDocumentTouched(false);
  };

  function validateDocument(docValue) {
    let isValid = true;
    if (docValue.replace(/\D/g, '').length === 0) {
      setDocumentError('Campo obrigatório.');
      isValid = false;
    } else if (documentType === 'CPF' && !isCPF(docValue)) {
      setDocumentError('Por favor, insira um CPF válido.');
      isValid = false;
    } else if (documentType === 'CNPJ' && !isCNPJ(docValue)) {
      setDocumentError('Por favor, insira um CNPJ válido.');
      isValid = false;
    } else {
      setDocumentError('');
    }
    return isValid;
  }

  const handleClose = () => {
    setOpenDialog(false);
    setCompensations(null);
  };

  const renderCertificationDetails = (compensations) => {
    // Calculate total offset from all "COMPENSATION" transactions
    const totalOffset = compensations.reduce((sum, transaction) => sum + parseFloat(transaction.offset), 0);
    const name = compensations[0]?.buyer.fullName;

    return (
      <div>
        <Typography><strong>{documentType}:</strong> {formatDocId(documentType, document)}</Typography>
        <Typography><strong>Nome:</strong> {name}</Typography>
        <Typography><strong>Total de Créditos Compensados:</strong> {totalOffset.toLocaleString("pt-BR")} CCO2</Typography>
        <Typography variant="h6" sx={{marginTop: 2, marginBottom: 2}}>TRANSAÇÕES</Typography>
        {compensations.map(renderTransactionCard)}
      </div>
    );
  }

  const renderTransactionCard = (transaction) => {
    const cardStyles = {
      marginBottom: 2,
      backgroundColor: "rgba(241,241,241,0.42)",
    };

    return (
      <Card key={transaction.key} sx={cardStyles}>
        <CardContent>
          <Typography><strong>Data:</strong> {transaction.date}</Typography>
          <Typography><strong>Chave:</strong> {transaction.key}</Typography>
          <Typography><strong>Quantidade:</strong> {transaction.offset} CCO2</Typography>
          <Typography><strong>Descrição:</strong> {transaction.description}</Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Container className="search-form">
      <Form noValidate validated={validated} ref={formRef} onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Form.Group as={Col} md="6" controlId="document" className="form-group">
            <h6 htmlFor="document">Consultar Compensação</h6>
            <TextField
              inputRef={documentInputRef}
              fullWidth
              id="document"
              name="documentInput"
              value={document}
              onChange={handleDocumentChange}
              onBlur={() => setDocumentTouched(true)}
              required
              placeholder={documentType === 'CPF' ? "CPF" : "CNPJ"}
              error={documentTouched && !!documentError}
              helperText={documentError}
              inputProps={{
                maxLength: documentType === 'CPF' ? 14 : 18,
                pattern: documentType === 'CPF' ? "[0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2}" : "[0-9]{2}.[0-9]{3}.[0-9]{3}/[0-9]{4}-[0-9]{2}"
              }}
            />
            <div style={{marginTop: 10}}>
              <Form.Check
                inline
                type="radio"
                label="CPF"
                name="documentType"
                value="CPF"
                checked={documentType === 'CPF'}
                onChange={handleTypeChange}
              />
              <Form.Check
                inline
                type="radio"
                label="CNPJ"
                name="documentType"
                value="CNPJ"
                checked={documentType === 'CNPJ'}
                onChange={handleTypeChange}
              />
            </div>
          </Form.Group>
        </Row>
        <button type="submit" className="btn-custom float-end" disabled={isLoading}>
          Consultar
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className="ms-2"
            hidden={!isLoading}
          />
        </button>
      </Form>
      <Dialog open={openDialog} onClose={handleClose} PaperProps={{sx: {borderRadius: 4}}}>
        <DialogTitle>Compensações</DialogTitle>
        <DialogContent>{compensations && renderCertificationDetails(compensations)}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer/>
    </Container>
  );
}

export default CompensationSearchForm;
