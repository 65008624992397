import React, { useState, useEffect } from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem, TextField } from '@mui/material';
import { FlagIcon } from 'react-flag-kit';
import { COUNTRY_CODES, PHONE_FORMATS } from '../../utils/constants';

const PhoneField = ({
  value = '',
  onChange,
  error,
}) => {
  const [selectedCountry, setSelectedCountry] = useState('BR');
  const [localPhone, setLocalPhone] = useState('');

  // Initialize phone number when editing existing record
  useEffect(() => {
    if (value) {
      // Extract country code and local number from full phone number
      const country = COUNTRY_CODES.find(c => value.startsWith(c.dialCode));
      if (country) {
        setSelectedCountry(country.code);
        // Remove country code and trim spaces to get local number
        const localNumber = value.replace(country.dialCode, '').trim();
        setLocalPhone(localNumber);
      }
    }
  }, [value]);

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
    setLocalPhone('');
    onChange('');
  };

  const handlePhoneChange = (e) => {
    const input = e.target.value;
    const cleaned = input.replace(/\D/g, '');
    const formatted = formatPhoneNumber(cleaned, selectedCountry);
    
    setLocalPhone(formatted);
    const fullNumber = `${COUNTRY_CODES.find(c => c.code === selectedCountry).dialCode} ${formatted}`;
    onChange(fullNumber);
  };

  const formatPhoneNumber = (phone, countryCode) => {
    if (!phone) return '';
    const cleaned = phone.replace(/\D/g, '');
    const formatter = PHONE_FORMATS[countryCode] || PHONE_FORMATS.BR;
    return formatter.mask(cleaned);
  };

  return (
    <Box display="flex" alignItems="flex-start" gap={1}>
      <FormControl sx={{ minWidth: 120 }}>
        <InputLabel id="country-code-label">Country</InputLabel>
        <Select
          labelId="country-code-label"
          value={selectedCountry}
          onChange={handleCountryChange}
          label="Country"
          sx={{
            '& .MuiSelect-select': {
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            },
          }}
        >
          {COUNTRY_CODES.map((country) => (
            <MenuItem 
              key={country.code} 
              value={country.code}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <FlagIcon code={country.flagCode} size={16} />
              <span>{country.dialCode}</span>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        fullWidth
        label="Phone"
        value={localPhone}
        onChange={handlePhoneChange}
        error={Boolean(error)}
        helperText={error || (PHONE_FORMATS[selectedCountry] ? `Format: ${PHONE_FORMATS[selectedCountry].example}` : '')}
      />
    </Box>
  );
};

export default PhoneField; 