import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../config/firebase';
import { doc, getDoc } from 'firebase/firestore';

const UserRoleContext = createContext(null);

export const UserRoleProvider = ({ children }) => {
  const [user] = useAuthState(auth);
  const [roleState, setRoleState] = useState({
    role: null,
    loading: true,
    isAdmin: false,
    isEmployee: false
  });

  useEffect(() => {
    const fetchUserRole = async () => {
      if (!user) {
        setRoleState({
          role: null,
          loading: false,
          isAdmin: false,
          isEmployee: false
        });
        return;
      }

      try {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        const role = userDoc.exists() ? userDoc.data()?.role : null;
        
        setRoleState({
          role,
          loading: false,
          isAdmin: role === 'admin',
          isEmployee: role === 'employee'
        });
      } catch (error) {
        console.error('Error fetching user role:', error);
        setRoleState({
          role: null,
          loading: false,
          isAdmin: false,
          isEmployee: false
        });
      }
    };

    fetchUserRole();
  }, [user]); // Only re-run if user changes (login/logout)

  return (
    <UserRoleContext.Provider value={roleState}>
      {children}
    </UserRoleContext.Provider>
  );
};

// Custom hook to use the user role context
export const useUserRole = () => {
  const context = useContext(UserRoleContext);
  if (context === null) {
    throw new Error('useUserRole must be used within a UserRoleProvider');
  }
  return context;
}; 