import React, {useEffect, useState} from "react";
import {auth} from "../../../config/firebase";
import {signInWithEmailAndPassword} from "firebase/auth";
import {Alert, Button, Container, Form} from "react-bootstrap";
import {useNavigate, Link} from "react-router-dom";
import ReCAPTCHAComponent from "../../security/recaptcha";
import {useAuthState} from "react-firebase-hooks/auth";
import "./login.scss";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const navigate = useNavigate();
  const [user, loading, authError] = useAuthState(auth);

  useEffect(() => {
    if (user) {
      navigate("/admin");
    }
  }, [user, navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    
    // Validação do formulário
    if (!email || !password) {
      setError("Please, fill in all fields.");
      return;
    }
    
    if (!captchaVerified) {
      setError("Please, confirm that you are not a robot.");
      return;
    }
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/admin");
    } catch (err) {
      if (err.message.includes('wrong-password')) { 
        setError("Invalid email or password.");
      } else {
        setError(err.message);
      }
      console.error("Login Error: ", err);  // Enhanced logging
    }
  };

  const handleCaptchaVerify = (value) => {
    setCaptchaVerified(!!value);
  };

  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <Container className="d-flex justify-content-center align-items-center min-vh-100">
      <div className="login-container p-4 rounded shadow-sm">
        <div className="text-center mb-4 d-flex align-items-center justify-content-center">
          <img 
            src="/assets/img/abcarbon_logo.png"
            alt="abcarbon"
            className="img-responsive login-logo"
          />
          <Link to="/" className='navbar-brand'>abcarbon</Link>
        </div>
        <h2 className="text-center mb-4" style={{fontSize: "24px"}}>Admin Login</h2>
        {error && <Alert variant="danger">{error}</Alert>}
        <Form onSubmit={handleLogin}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="form-control-lg"
            />
          </Form.Group>

          <Form.Group className="mb-4" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="form-control-lg"
            />
          </Form.Group>

          <div className="mb-4 d-flex justify-content-center">
            <ReCAPTCHAComponent onChange={handleCaptchaVerify} />
          </div>

          <Button 
            variant="primary" 
            type="submit" 
            disabled={loading}
            className="w-100 py-2 mb-3"
            size="lg"
          >
            {loading ? "Loading..." : "Login"}
          </Button>
        </Form>
      </div>
    </Container>
  );
}
