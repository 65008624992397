import React, { useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import TextField from './FormFields/TextField';

const PropertyDetails = ({
  certification,
  handleFieldChange,
  errors,
}) => {
  
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          required
          label="CAR"
          value={certification.data?.car}
          onChange={(value) => handleFieldChange('car', value.toUpperCase())}
          error={errors.car}
        />
      </Grid>
      <Grid item xs={12}>
        <div style={{ display: 'flex', gap: '16px', alignItems: 'flex-start' }}>
          <div style={{ width: '30%' }}>
            <TextField
              fullWidth
              required
              label="Matriculation"
              value={certification.data?.currentMatriculation || ''}
              onChange={(value) => {
                // Only allow numbers
                const numericValue = value.replace(/[^0-9]/g, '');
                handleFieldChange('currentMatriculation', numericValue); // Removed to prevent saving
              }}
              error={errors.matriculation}
              inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*'
              }}
            />
          </div>
          <button
            onClick={() => {
              if (certification.data?.currentMatriculation) {
                const currentMatriculation = certification.data?.matriculation || [];
                handleFieldChange('matriculation', [...currentMatriculation, certification.data.currentMatriculation]);
                handleFieldChange('currentMatriculation', '');
                delete certification.data.currentMatriculation;
              }
            }}
            style={{
              minWidth: '40px',
              height: '55.97px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              backgroundColor: '#fff',
              cursor: 'pointer'
            }}
          >
            +
          </button>
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px', flex: 1, marginTop: '8px' }}>
            {certification.data?.matriculation?.map((number, index) => (
              <div
                key={index}
                style={{
                  backgroundColor: '#e0e0e0',
                  borderRadius: '16px',
                  padding: '4px 12px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px'
                }}
              >
                <span>{number}</span>
                <span
                  onClick={() => {
                    const newMatriculation = certification.data.matriculation.filter((_, i) => i !== index);
                    handleFieldChange('matriculation', newMatriculation);
                  }}
                  style={{
                    cursor: 'pointer',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    color: '#666'
                  }}
                >
                  ×
                </span>
              </div>
            ))}
          </div>
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          required
          label="Property Name"
          value={certification.data?.address?.propertyName}
          onChange={(value) => handleFieldChange('address.propertyName', value)}
          error={errors['address.propertyName']}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          required
          label="Property Area (ha)"
          type="number"
          value={certification.data?.propertyArea}
          onChange={(value) => handleFieldChange('propertyArea', parseFloat(value))}
          error={errors.propertyArea}
        />
      </Grid>
    </Grid>
  );
};

export default PropertyDetails; 