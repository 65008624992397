import React, { useEffect, useState } from 'react';
import { Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Fab, Box, Tab, Tabs, Typography, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import { Delete, Edit, Add, Archive, Unarchive } from '@mui/icons-material';
import { db } from '../../../config/firebase';
import { collection, deleteDoc, doc, getDocs, setDoc, updateDoc } from 'firebase/firestore';
import CertificationDialog from './components/certification-dialog/index';
import { Refresh } from '@mui/icons-material';
import { generateMD5Hash } from './components/certification-dialog/utils/hashs';
import { Timestamp } from 'firebase/firestore';
import { useUserRole } from '../../../contexts/UserRoleContext';
import { TabContext, TabPanel } from '@mui/lab';

const ManageCertifications = ({ user }) => {
  const [certifications, setCertifications] = useState([]);
  const [archivedCertifications, setArchivedCertifications] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentCertification, setCurrentCertification] = useState({ id: '', data: {} });
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('registrationDate');
  const [tabValue, setTabValue] = useState('active');
  const { isAdmin, isEmployee } = useUserRole();
  const [archiveDialogOpen, setArchiveDialogOpen] = useState(false);
  const [certificationToArchive, setCertificationToArchive] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [certificationToDelete, setCertificationToDelete] = useState(null);
  const [deleteConfirmationText, setDeleteConfirmationText] = useState('');

  useEffect(() => {
    fetchCertifications();
  }, []);

  const fetchCertifications = async () => {
    const certificationsCol = collection(db, 'certifications');
    const certificationSnapshot = await getDocs(certificationsCol);
    const certificationList = certificationSnapshot.docs.map(doc => ({
      id: doc.id,
      data: doc.data(),
    }));
    
    // Split certifications into active and archived
    const active = certificationList.filter(cert => !cert.data.archived);
    const archived = certificationList.filter(cert => cert.data.archived);
    
    setCertifications(active);
    setArchivedCertifications(archived);
  };

  const handleOpen = (certification) => {
    if (!certification.id) {
      // This is a new certification
      setCurrentCertification({
        id: '',
        data: {
          requester: {},
          address: {},
          owners: [],
          transactions: [],
          baseYear: {},
          status: { custody: 'ABCARBON' }
        }
      });
    } else {
      setCurrentCertification(certification);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentCertification({ id: '', data: {} });
    //setCurrentTransaction({});
    fetchCertifications();
  };

  const handleSave = async () => {
    try {
      // Get the certification ID from either the current ID or from the form data
      const certificationId = currentCertification.id || currentCertification.data.id;
      if (!certificationId && !currentCertification.data.certificationKey) {
        // If we don't have an ID, use the certification key as the document ID
        console.error('No certification key provided.');
        return;
      }

      // Remove transient fields.
      const { currentMatriculation, ...restData } = currentCertification.data;
      let updatedData = { ...restData };

      // For a new certification, add the creation transaction.
      if (!currentCertification.id) {
        const creationTransaction = buildCreationTransaction(updatedData);
        const updatedTransactions = [...(updatedData.transactions || []), creationTransaction];
        updatedData = { ...updatedData, transactions: updatedTransactions };
      }

      const docId = certificationId || updatedData.certificationKey;
      const docRef = doc(db, 'certifications', docId);

      if (currentCertification.id) {
        // Updating an existing certification.
        await updateDoc(docRef, updatedData);
      } else {
        // Creating a new certification.
        await setDoc(docRef, updatedData);
      }

      // Update local state with the new data.
      setCurrentCertification({
        ...currentCertification,
        data: updatedData
      });

      handleClose();
    } catch (error) {
      console.error('Error saving certification:', error);
    }
  };

  const handleDelete = async (id) => {
    await deleteDoc(doc(db, 'certifications', id));
    await fetchCertifications();
  };

  const handleAddOwner = (newOwner) => {
    const updatedOwners = [...(currentCertification.data.owners || []), newOwner];
    setCurrentCertification({
      ...currentCertification,
      data: { ...currentCertification.data, owners: updatedOwners }
    });
  };

  const handleDeleteOwner = (index) => {
    const updatedOwners = currentCertification.data.owners.filter((_, i) => i !== index);
    setCurrentCertification({
      ...currentCertification,
      data: { ...currentCertification.data, owners: updatedOwners }
    });
  };

  const handleAddTransaction = async (type, description, additionalData = {}) => {
    const now = Timestamp.now();
    const transactionKey = generateMD5Hash(currentCertification.data.certificationKey + now.toDate().toISOString());

    const newTransaction = {
      date: now,
      key: transactionKey,
      type,
      description,
      createdBy: {
        email: user.email,
        uid: user.uid
      },
      ...additionalData
    };

    const updatedTransactions = [...(currentCertification.data.transactions || []), newTransaction];
    setCurrentCertification({
      ...currentCertification,
      data: { ...currentCertification.data, transactions: updatedTransactions }
    });
    console.log('newTransaction', newTransaction);
  };

  const handleDeleteTransaction = (index) => {
    const updatedTransactions = currentCertification.data.transactions.filter((_, i) => i !== index);
    setCurrentCertification({
      ...currentCertification,
      data: { ...currentCertification.data, transactions: updatedTransactions }
    });
  };

  const handleFieldChange = (field, value) => {
    setCurrentCertification(prev => {
      const newData = { ...prev.data };

      // Handle nested fields (e.g., 'requester.fullName', 'address.city')
      if (field.includes('.')) {
        const fields = field.split('.');
        let current = newData;

        // Handle array notation (e.g., 'owners.0.fullName')
        const path = fields.map(f => f.replace(/\[\d+\]/, '')); // Remove array indices
        const arrayMatch = field.match(/\.(\d+)\./); // Extract array index if present
        const arrayIndex = arrayMatch ? parseInt(arrayMatch[1]) : null;

        // Navigate through the object until we reach the last parent
        for (let i = 0; i < path.length - 1; i++) {
          const key = path[i];

          // Initialize arrays if needed
          if (arrayIndex !== null && key === 'owners') {
            if (!Array.isArray(current[key])) {
              current[key] = [];
            }
            while (current[key].length <= arrayIndex) {
              current[key].push({});
            }
            current = current[key][arrayIndex];
            i++; // Skip the index part in the path
            continue;
          }

          // Initialize objects if needed
          if (!current[key]) {
            current[key] = {};
          }
          current = current[key];
        }

        // Set the final field value
        current[path[path.length - 1]] = value;
      } else {
        // Handle top-level fields
        newData[field] = value;
      }

      const updatedCertification = {
        ...prev,
        data: newData
      };

      return updatedCertification;
    });
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedCertifications = certifications.slice().sort((a, b) => {
    if (orderBy === 'registrationDate') {
      return (order === 'desc' ? b.data.registrationDate - a.data.registrationDate : a.data.registrationDate - b.data.registrationDate);
    }
    if (orderBy === 'requester') {
      return (order === 'desc' ? b.data.requester.fullName.localeCompare(a.data.requester.fullName) : a.data.requester.fullName.localeCompare(b.data.requester.fullName));
    }
    if (orderBy === 'propertyName') {
      return (order === 'desc' ? b.data.address.propertyName.localeCompare(a.data.address.propertyName) : a.data.address.propertyName.localeCompare(b.data.address.propertyName));
    }
    return 0;
  });

  function buildCreationTransaction(updatedData) {
    const now = Timestamp.now();
    const transactionKey = generateMD5Hash(
      updatedData.certificationKey + now.toDate().toISOString()
    );
    const newTransaction = {
      date: now,
      key: transactionKey,
      type: 'CREATION',
      description: 'Certification created.',
      createdBy: {
        email: user.email,
        uid: user.uid
      }
    };
    return newTransaction;
  }
  
  function formatTimestamp(timestamp) {
    if (!timestamp) return 'Timestamp not available.';

    const date = timestamp.toDate();

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}/${month}/${day}`;
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    fetchCertifications();
  };

  const handleArchiveClick = (certification) => {
    setCertificationToArchive(certification);
    setArchiveDialogOpen(true);
  };

  const handleArchiveConfirm = async () => {
    try {
      const docRef = doc(db, 'certifications', certificationToArchive.id);
      const now = Timestamp.now();
      const transactionKey = generateMD5Hash(
        certificationToArchive.data.certificationKey + now.toDate().toISOString()
      );

      const newTransaction = {
        date: now,
        key: transactionKey,
        type: 'ARCHIVED',
        description: 'Document archived.',
        createdBy: {
          email: user.email,
          uid: user.uid
        }
      };

      const updatedTransactions = [...(certificationToArchive.data.transactions || []), newTransaction];
      
      await updateDoc(docRef, {
        archived: true,
        transactions: updatedTransactions
      });
      
      await fetchCertifications();
      setArchiveDialogOpen(false);
      setCertificationToArchive(null);
    } catch (error) {
      console.error('Error archiving certification:', error);
    }
  };

  const handleUnarchiveClick = (certification) => {
    setCertificationToArchive(certification);
    setArchiveDialogOpen(true);
  };

  const handleUnarchiveConfirm = async () => {
    try {
      const docRef = doc(db, 'certifications', certificationToArchive.id);
      const now = Timestamp.now();
      const transactionKey = generateMD5Hash(
        certificationToArchive.data.certificationKey + now.toDate().toISOString()
      );

      const newTransaction = {
        date: now,
        key: transactionKey,
        type: 'UNARCHIVED',
        description: 'Document unarchived.',
        createdBy: {
          email: user.email,
          uid: user.uid
        }
      };

      const updatedTransactions = [...(certificationToArchive.data.transactions || []), newTransaction];
      
      await updateDoc(docRef, {
        archived: false,
        transactions: updatedTransactions
      });
      
      await fetchCertifications();
      setArchiveDialogOpen(false);
      setCertificationToArchive(null);
    } catch (error) {
      console.error('Error unarchiving certification:', error);
    }
  };

  const handleArchiveCancel = () => {
    setArchiveDialogOpen(false);
    setCertificationToArchive(null);
  };

  const handleDeleteClick = (certification) => {
    setCertificationToDelete(certification);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await deleteDoc(doc(db, 'certifications', certificationToDelete.id));
      await fetchCertifications();
      setDeleteDialogOpen(false);
      setCertificationToDelete(null);
      setDeleteConfirmationText('');
    } catch (error) {
      console.error('Error deleting certification:', error);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setCertificationToDelete(null);
    setDeleteConfirmationText('');
  };

  const renderCertificationsTable = (certifications, isArchived = false) => (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'registrationDate'}
                direction={orderBy === 'registrationDate' ? order : 'asc'}
                onClick={() => handleRequestSort('registrationDate')}
              >
                Data
              </TableSortLabel>
            </TableCell>
            <TableCell>Chave</TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'requester'}
                direction={orderBy === 'requester' ? order : 'asc'}
                onClick={() => handleRequestSort('requester')}
              >
                Solicitante
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'propertyName'}
                direction={orderBy === 'propertyName' ? order : 'asc'}
                onClick={() => handleRequestSort('propertyName')}
              >
                Propriedade
              </TableSortLabel>
            </TableCell>
            <TableCell>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {certifications.map((cert) => (
            <TableRow key={cert.id}>
              <TableCell>{formatTimestamp(cert.data.registrationDate)}</TableCell>
              <TableCell>{cert.id}</TableCell>
              <TableCell>{cert.data.requester?.fullName}</TableCell>
              <TableCell>{cert.data.address?.propertyName}</TableCell>
              <TableCell>
                <IconButton onClick={() => handleOpen(cert)}>
                  <Edit />
                </IconButton>
                {!isArchived && (
                  <IconButton 
                    onClick={() => handleArchiveClick(cert)}
                    hidden={!isAdmin && !isEmployee}
                    disabled={!isAdmin && !isEmployee}
                  >
                    <Archive />
                  </IconButton>
                )}
                {isArchived && (
                  <>
                    <IconButton 
                      onClick={() => handleUnarchiveClick(cert)}
                      hidden={!isAdmin && !isEmployee}
                      disabled={!isAdmin && !isEmployee}
                    >
                      <Unarchive />
                    </IconButton>
                    <IconButton 
                      onClick={() => handleDeleteClick(cert)}
                      hidden={!isAdmin}
                      disabled={!isAdmin}
                    >
                      <Delete />
                    </IconButton>
                  </>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <div style={{ position: 'relative' }}>
      <h2>Manage Certifications</h2>

      <Box sx={{ position: 'absolute', top: 0, right: 0, margin: 2, display: 'flex', gap: 1 }}>
        <Fab
          color="secondary"
          aria-label="refresh"
          onClick={fetchCertifications}
          sx={{
            '&:hover': {
              transform: 'scale(1.05)',
            },
            transition: 'transform 0.2s',
          }}
        >
          <Refresh />
        </Fab>
        <Fab
          color="primary"
          aria-label="add"
          onClick={() => handleOpen({ id: '', data: {} })}
          sx={{
            '&:hover': {
              transform: 'scale(1.05)',
            },
            transition: 'transform 0.2s',
          }}
        >
          <Add />
        </Fab>
      </Box>

      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="certification tabs"
          >
            <Tab label="Active" value="active" />
            <Tab label="Archived" value="archived" />
          </Tabs>
        </Box>

        <TabPanel value="active">
          {renderCertificationsTable(certifications)}
        </TabPanel>

        <TabPanel value="archived">
          {renderCertificationsTable(archivedCertifications, true)}
        </TabPanel>
      </TabContext>

      <CertificationDialog
        user={user}
        open={open}
        onClose={handleClose}
        certification={currentCertification}
        handleFieldChange={handleFieldChange}
        handleAddOwner={handleAddOwner}
        handleDeleteOwner={handleDeleteOwner}
        handleSave={handleSave}
      />

      <Dialog
        open={archiveDialogOpen}
        onClose={handleArchiveCancel}
        aria-labelledby="archive-dialog-title"
      >
        <DialogTitle id="archive-dialog-title">
          {certificationToArchive?.data?.archived ? 'Unarchive' : 'Archive'} Certification
        </DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to {certificationToArchive?.data?.archived ? 'unarchive' : 'archive'} this certification?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleArchiveCancel}>Cancel</Button>
          <Button 
            onClick={certificationToArchive?.data?.archived ? handleUnarchiveConfirm : handleArchiveConfirm} 
            color="primary" 
            variant="contained"
          >
            {certificationToArchive?.data?.archived ? 'Unarchive' : 'Archive'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="delete-dialog-title"
      >
        <DialogTitle id="delete-dialog-title">
          Delete Certification
        </DialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            Are you sure you want to delete this certification? This action cannot be undone.
          </Typography>
          <Typography variant="body2" color="error" gutterBottom>
            Type DELETE to confirm.
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            fullWidth
            value={deleteConfirmationText}
            onChange={(e) => setDeleteConfirmationText(e.target.value)}
            error={deleteConfirmationText !== '' && deleteConfirmationText !== 'DELETE'}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>Cancel</Button>
          <Button 
            onClick={handleDeleteConfirm} 
            color="error" 
            variant="contained"
            disabled={deleteConfirmationText !== 'DELETE'}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ManageCertifications;
