import md5 from 'md5';
import sha1 from 'sha1';

export const generateMD5Hash = (value) => {
  return md5(value);
};

export const generateSHA1Hash = (value) => {
  return sha1(value);
};
