import React from 'react';
import { Box, Typography, Button, List, ListItem, Grid, IconButton } from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import DocumentField from './FormFields/DocumentField';
import TextField from './FormFields/TextField';

const OwnersList = ({
  owners = [],
  handleFieldChange,
  handleDeleteOwner,
  handleAddOwner,
  errors,
}) => {
  const handleAddOwnerWithValidation = () => {
    handleAddOwner({ fullName: '', docId: '', docType: 'CPF' });
  };

  return (
    <Box mb={3}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="subtitle1" gutterBottom>Owners</Typography>
        <Button
          startIcon={<Add />}
          onClick={handleAddOwnerWithValidation}
        >
          Add Owner
        </Button>
      </Box>
      <List>
        {owners.map((owner, index) => (
          <ListItem key={index}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={4}>
                <TextField
                  fullWidth
                  label="Full Name"
                  value={owner.fullName}
                  onChange={(value) => handleFieldChange(`owners.${index}.fullName`, value)}
                  error={errors[`owners.${index}.fullName`]}
                  required
                />
              </Grid>

              <DocumentField
                document={owner}
                prefix={`owners.${index}`}
                handleFieldChange={handleFieldChange}
                errors={errors}
                lastFieldXs={5}
              />

              <Grid item xs={1}>
                <IconButton onClick={() => handleDeleteOwner(index)}>
                  <Delete />
                </IconButton>
              </Grid>
            </Grid>
          </ListItem>
        ))}
      </List>
      {(!owners || owners.length === 0) && (
        <Typography
          color="error"
          variant="caption"
          sx={{ display: 'block', mt: 1, ml: 2 }}
        >
          At least one owner should be added.
        </Typography>
      )}
    </Box>
  );
};

export default OwnersList; 