import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserRole } from '../../../contexts/UserRoleContext';
import { 
  Button, 
  IconButton, 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  TableSortLabel, 
  Fab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Typography
} from '@mui/material';
import { Delete, Edit, Add, Refresh } from '@mui/icons-material';
import { db } from '../../../config/firebase';
import { collection, getDocs } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { userApi } from '../../../config/firebase';

const ManageUsers = ({ user }) => {
  const { isAdmin, loading: roleLoading } = useUserRole();
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState({ id: '', data: {} });
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('displayName');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [newUser, setNewUser] = useState({
    email: "",
    displayName: "",
    role: "employee",
    active: true,
  });
  const [selectedUser, setSelectedUser] = useState(null);

  const fetchUsers = async () => {
    try {
      const usersCol = collection(db, 'users');
      const userSnapshot = await getDocs(usersCol);
      const userList = userSnapshot.docs.map(doc => ({
        id: doc.id,
        data: doc.data(),
      }));
      setUsers(userList);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching users:', error);
      toast.error('Error fetching users');
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!roleLoading && !isAdmin) {
      navigate('/admin', { replace: true });
      return;
    }

    if (!roleLoading && isAdmin) {
      fetchUsers();
    }
  }, [isAdmin, roleLoading, navigate]);

  const handleOpen = (user) => {
    setCurrentUser(!user.id ? {
      id: '',
      data: {
        email: '',
        displayName: '',
        role: 'employee',
        active: true
      }
    } : user);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentUser({ id: '', data: {} });
  };

  const handleCreateUser = async (userData) => {
    try {
      setIsLoading(true);
      const result = await userApi.create({
        email: userData.email,
        displayName: userData.displayName,
        role: userData.role,
        active: userData.active
      });
      toast.success("User created successfully!");
      setOpen(false);
      setNewUser({
        email: "",
        displayName: "",
        role: "employee",
        active: true,
      });
      fetchUsers();
    } catch (error) {
      console.error("Error creating user:", error);
      toast.error("Error creating user: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateUser = async (userData) => {
    try {
      setIsLoading(true);
      await userApi.update({
        uid: selectedUser.id,
        displayName: userData.displayName,
        role: userData.role,
        active: userData.active
      });
      toast.success("User updated successfully!");
      setOpen(false);
      setSelectedUser(null);
      fetchUsers();
    } catch (error) {
      console.error("Error updating user:", error);
      toast.error("Error updating user: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteUser = async () => {
    try {
      setIsLoading(true);
      await userApi.delete({ uid: selectedUser.id });
      toast.success("User deleted successfully!");
      setOpen(false);
      setSelectedUser(null);
      fetchUsers();
    } catch (error) {
      console.error("Error deleting user:", error);
      toast.error("Error deleting user: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFieldChange = (field, value) => {
    setCurrentUser(prev => ({
      ...prev,
      data: {
        ...prev.data,
        [field]: value
      }
    }));
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedUsers = users.slice().sort((a, b) => {
    if (orderBy === 'email') {
      return (order === 'desc' ? b.data.email.localeCompare(a.data.email) : a.data.email.localeCompare(b.data.email));
    }
    if (orderBy === 'displayName') {
      return (order === 'desc' ? b.data.displayName.localeCompare(a.data.displayName) : a.data.displayName.localeCompare(b.data.displayName));
    }
    if (orderBy === 'role') {
      return (order === 'desc' ? b.data.role.localeCompare(a.data.role) : a.data.role.localeCompare(b.data.role));
    }
    return 0;
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ position: 'relative' }}>
      <h2>Manage Users</h2>

      <Box sx={{ position: 'absolute', top: 0, right: 0, margin: 2, display: 'flex', gap: 1 }}>
        <Fab
          color="secondary"
          aria-label="refresh"
          onClick={fetchUsers}
          sx={{
            '&:hover': {
              transform: 'scale(1.05)',
            },
            transition: 'transform 0.2s',
          }}
        >
          <Refresh />
        </Fab>
        <Fab
          color="primary"
          aria-label="add"
          onClick={() => handleOpen({ id: '', data: {} })}
          sx={{
            '&:hover': {
              transform: 'scale(1.05)',
            },
            transition: 'transform 0.2s',
          }}
        >
          <Add />
        </Fab>
      </Box>

      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'displayName'}
                  direction={orderBy === 'displayName' ? order : 'asc'}
                  onClick={() => handleRequestSort('displayName')}
                >
                  Name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'email'}
                  direction={orderBy === 'email' ? order : 'asc'}
                  onClick={() => handleRequestSort('email')}
                >
                  Email
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'uid'}
                  direction={orderBy === 'uid' ? order : 'asc'}
                  onClick={() => handleRequestSort('uid')}
                >
                  UID
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'role'}
                  direction={orderBy === 'role' ? order : 'asc'}
                  onClick={() => handleRequestSort('role')}
                >
                  Role
                </TableSortLabel>
              </TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedUsers.map((userRow) => (
              <TableRow key={userRow.id}>
                <TableCell>{userRow.data.displayName}</TableCell>
                <TableCell>{userRow.data.email}</TableCell>
                <TableCell>{userRow.id}</TableCell>
                <TableCell>{userRow.data.role}</TableCell>
                <TableCell>{userRow.data.active ? 'Active' : 'Inactive'}</TableCell>
                <TableCell>
                  <IconButton 
                    onClick={() => handleOpen(userRow)}
                    disabled={user.uid === userRow.id}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton 
                    onClick={() => setSelectedUser(userRow)}
                    disabled={user.uid === userRow.id}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{currentUser.id ? 'Edit User' : 'Create User'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            fullWidth
            value={currentUser.id ? currentUser.data.displayName : newUser.displayName}
            onChange={(e) => currentUser.id ? handleFieldChange('displayName', e.target.value) : setNewUser({...newUser, displayName: e.target.value})}
            disabled={currentUser.id === user.uid}
          />
          <TextField
            margin="dense"
            label="Email"
            type="email"
            fullWidth
            value={currentUser.id ? currentUser.data.email : newUser.email}
            onChange={(e) => currentUser.id ? handleFieldChange('email', e.target.value) : setNewUser({...newUser, email: e.target.value})}
            disabled={!!currentUser.id}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Role</InputLabel>
            <Select
              value={currentUser.id ? currentUser.data.role : newUser.role}
              label="Role"
              onChange={(e) => currentUser.id ? handleFieldChange('role', e.target.value) : setNewUser({...newUser, role: e.target.value})}
              disabled={currentUser.id === user.uid}
            >
              <MenuItem value="admin">Admin</MenuItem>
              <MenuItem value="employee">Employee</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <InputLabel>Status</InputLabel>
            <Select
              value={currentUser.id ? (currentUser.data.active ? 'active' : 'inactive') : (newUser.active ? 'active' : 'inactive')}
              label="Status"
              onChange={(e) => currentUser.id ? handleFieldChange('active', e.target.value === 'active') : setNewUser({...newUser, active: e.target.value === 'active'})}
              disabled={currentUser.id === user.uid}
            >
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="inactive">Inactive</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={isLoading}>Cancel</Button>
          <Button 
            onClick={() => handleCreateUser(currentUser.id ? currentUser.data : newUser)} 
            variant="contained" 
            color="primary"
            disabled={isLoading || currentUser.id === user.uid}
          >
            {isLoading ? 'Saving...' : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!selectedUser} onClose={() => setSelectedUser(null)}>
        <DialogTitle>Delete User</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this user?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSelectedUser(null)}>Cancel</Button>
          <Button 
            onClick={handleDeleteUser} 
            variant="contained" 
            color="primary"
            disabled={isLoading}
          >
            {isLoading ? 'Deleting...' : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ManageUsers; 