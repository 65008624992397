import React, { useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import TextField from './FormFields/TextField';
import PhoneField from './FormFields/PhoneField';
import DatePickerField from './FormFields/DatePickerField';

const RequestInformation = ({
  certification,
  handleFieldChange,
  errors,
}) => {

  return (
    <Grid item xs={12} style={{ border: '1px solid #ccc', margin: '15px 0 0 25px', padding: '20px', borderRadius: '8px' }}>
      <Typography variant="h6" gutterBottom>Request Information</Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Requester Full Name"
            value={certification.data?.requester?.fullName || ''}
            onChange={(value) => handleFieldChange('requester.fullName', value)}
            error={errors['requester.fullName']}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Requester Email"
            value={certification.data?.requester?.email || ''}
            onChange={(value) => handleFieldChange('requester.email', value)}
            error={errors['requester.email']}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DatePickerField
            label="Request Date"
            value={certification.data?.date}
            onChange={(date) => handleFieldChange('date', date)}
            error={errors.date}
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <PhoneField
            label="Requester Phone"
            value={certification.data?.requester?.phone}
            onChange={(value) => handleFieldChange('requester.phone', value)}
            error={errors['requester.phone']}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RequestInformation; 