import React, { useState, useEffect } from 'react';
import {
  Box,
  Tab,
  Tabs,
  Paper,
  TextField,
  Button,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Check, Close } from '@mui/icons-material';
import { TabPanel, TabContext } from '@mui/lab';
import { auth } from '../../../config/firebase';
import { updatePassword } from 'firebase/auth';
import { toast } from 'react-toastify';

const passwordRules = [
  { id: 'length', label: 'At least 8 characters long', test: (pwd) => pwd.length >= 8 },
  { id: 'lowercase', label: 'Contains lowercase letter', test: (pwd) => /[a-z]/.test(pwd) },
  { id: 'uppercase', label: 'Contains uppercase letter', test: (pwd) => /[A-Z]/.test(pwd) },
  { id: 'number', label: 'Contains number', test: (pwd) => /\d/.test(pwd) },
  { id: 'symbol', label: 'Contains symbol', test: (pwd) => /[!@#$%^&*(),.?":{}|<>]/.test(pwd) },
  { id: 'match', label: 'Passwords match', test: (pwd, confirmPwd) => pwd === confirmPwd && pwd !== '' }
];

const UserSettings = ({ user }) => {
  const [tabValue, setTabValue] = useState('password');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [validations, setValidations] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Check password rules whenever password or confirmPassword changes
  useEffect(() => {
    const newValidations = passwordRules.reduce((acc, rule) => ({
      ...acc,
      [rule.id]: rule.test(password, confirmPassword)
    }), {});
    setValidations(newValidations);
  }, [password, confirmPassword]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    
    // Check if all validations pass
    const allValid = Object.values(validations).every(v => v);
    if (!allValid) {
      toast.error('Please ensure all password requirements are met.');
      return;
    }

    setIsSubmitting(true);
    try {
      //const user = auth.currentUser;
      await updatePassword(user, password);
      toast.success('Password updated successfully.');
      setPassword('');
      setConfirmPassword('');
    } catch (error) {
      console.error('Error updating password:', error);
      if (error.message.includes('requires-recent-login')) {
        toast.error('Please log in again to update your password.');
      } else {
        toast.error(error.message || 'Error updating password.');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box sx={{ width: '100%', maxWidth: 800, margin: '0 auto', p: 3 }}>
      <Typography variant="h4" gutterBottom>
        User Settings
      </Typography>

      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="settings tabs"
          >
            <Tab label="Password" value="password" />
          </Tabs>
        </Box>

        <TabPanel value="password">
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Change Password
            </Typography>
            
            <form onSubmit={handlePasswordChange}>
              <TextField
                fullWidth
                type="password"
                label="New Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                margin="normal"
                autoComplete="new-password"
              />
              
              <TextField
                fullWidth
                type="password"
                label="Confirm New Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                margin="normal"
                autoComplete="new-password"
              />

              <Box sx={{ mt: 2, mb: 3 }}>
                <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                  Password Requirements:
                </Typography>
                <List dense>
                  {passwordRules.map((rule) => (
                    <ListItem key={rule.id} dense>
                      <ListItemIcon sx={{ minWidth: 36 }}>
                        {validations[rule.id] ? (
                          <Check color="success" />
                        ) : (
                          <Close color="error" />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary={rule.label}
                        sx={{
                          color: validations[rule.id] ? 'success.main' : 'error.main'
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting || !Object.values(validations).every(v => v)}
              >
                {isSubmitting ? 'Updating...' : 'Update Password'}
              </Button>
            </form>
          </Paper>
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default UserSettings; 