import React, { useState } from 'react';
import { Link, Navigate, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import {
  AppBar,
  Box,
  CssBaseline,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  useTheme,
  useMediaQuery,
  Stack,
  Grid
} from '@mui/material';
import { Dashboard, Logout, Settings, Menu as MenuIcon, People } from '@mui/icons-material';
import { signOut } from "firebase/auth";
import { auth } from "../../../config/firebase";
import ManageCertifications from './manage-certifications';
import ManageUsers from './manage-users';
import UserSettings from './user-settings';
import { useAuthState } from "react-firebase-hooks/auth";
import { useUserRole } from '../../../contexts/UserRoleContext';
const drawerWidth = 240;

// Define menu items separately for better organization
const menuItems = [
  {
    text: 'Manage Certifications',
    icon: <Dashboard />,
    roles: ['admin', 'employee'],
    path: '/admin/manage-certifications'
  },
  {
    text: 'Manage Users',
    icon: <People />,
    roles: ['admin'],
    path: '/admin/manage-users'
  },
  {
    text: 'User Settings',
    icon: <Settings />,
    roles: ['admin', 'employee'],
    path: '/admin/user-settings'
  }
];

export default function AdminDashboard() {
  const [user, loading, error] = useAuthState(auth);
  const { role: userRole } = useUserRole();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/login");
    } catch (err) {
      console.error("Logout Error: ", err);  // Enhanced logging
    }
  };

  const drawer = (
    <Box sx={{ overflow: 'auto' }}>
      <List sx={{ px: 1 }}>
        {menuItems.filter(item => item.roles.includes(userRole || 'employee')).map((item) => (
          <ListItem
            key={item.text}
            component={Link}
            to={item.path}
            onClick={() => isMobile && handleDrawerToggle()}
            sx={{
              backgroundColor: location.pathname === item.path ? 'action.selected' : 'transparent',
              '&:hover': {
                backgroundColor: 'action.hover',
              },
              borderRadius: 1,
              mb: 0.5,
              color: location.pathname === item.path ? 'primary.main' : 'text.primary',
            }}
          >
            <ListItemIcon
              sx={{
                color: location.pathname === item.path ? 'primary.main' : 'inherit',
                minWidth: 40,
              }}
            >
              {item.icon}
            </ListItemIcon>
            <ListItemText
              primary={item.text}
              sx={{
                '& .MuiListItemText-primary': {
                  fontWeight: location.pathname === item.path ? 600 : 400,
                }
              }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  if (loading) return <div>Loading...</div>;

  return (
    <Box sx={{ minWidth: "450px", display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          left: 0,
          minWidth: "450px",
          zIndex: (theme) => theme.zIndex.drawer + 1
        }}
      >
        <Toolbar>
          <Grid container alignItems="center" spacing={2}>
            {/* Left section with menu and title */}
            <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
              {isMobile && (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ mr: 1 }}
                >
                  <MenuIcon />
                </IconButton>
              )}
              <Box>
                <Typography variant="h6" noWrap component="div"
                  sx={{
                    fontSize: {
                      xs: '1rem',  // Smaller font size for xs screens
                      sm: '1.25rem',
                      md: '1.5rem',
                      lg: '1.75rem',  // Bigger font size for large screens
                    }
                  }}>
                  ABCARBON - Admin Dashboard
                </Typography>
                {user && isMobile && (
                  <Typography variant="body2" sx={{ mt: {xs: 0} }}>
                    {user.email}
                  </Typography>
                )}
              </Box>
            </Grid>

            {/* Right section with user info and logout */}
            <Grid item xs sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              {user && !isMobile && (
                <Stack direction="row" spacing={2} alignItems="center">
                  <Typography variant="body1">
                    Logged in as: {user.email}
                  </Typography>
                </Stack>
              )}
              {user && (
                <IconButton color="inherit" onClick={handleLogout} sx={{ ml: 2 }}>
                  <Logout />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 }
        }}
      >
        {/* Mobile drawer */}
        <Drawer
          variant="temporary"
          anchor="left"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              width: drawerWidth,
              boxSizing: 'border-box',
            }
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            }
          }}
        >
          <Toolbar />
          {drawer}
        </Drawer>

        {/* Desktop drawer */}
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: 'border-box',
              overflowX: 'hidden',
            },
          }}
        >
          <Toolbar />
          {drawer}
        </Drawer>
      </Box>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: 'background.default',
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` }
        }}
      >
        <Toolbar />
        <Routes>
          <Route path="/" element={<Navigate to="manage-certifications" />} />
          <Route path="manage-certifications" element={<ManageCertifications user={user} />} />
          <Route path="manage-users" element={<ManageUsers user={user} />} />
          <Route path="user-settings" element={<UserSettings user={user} />} />
        </Routes>
      </Box>
    </Box>
  );
}
