const config = {
  // Override with REACT_APP_USE_EMULATORS env var, otherwise use development check
  useEmulators: process.env.NODE_ENV === 'development',
  emulatorHost: 'localhost',
  functionsEmulatorPort: 5001,
  firestoreEmulatorPort: 8204,
  firebase: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  },
};

export default config;
