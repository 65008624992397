import React from 'react';
import { Grid, Typography } from '@mui/material';
import TextField from './FormFields/TextField';
import DatePickerField from './FormFields/DatePickerField';

const CarbonInformation = ({
  certification,
  handleFieldChange,
  errors,
}) => {
  return (
    <Grid item xs={12} style={{ border: '1px solid #ccc', margin: '15px 0 0 25px', padding: '20px', borderRadius: '8px' }}>
      <Typography variant="h6" gutterBottom>Carbon Information</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            required
            label="Carbon Credit"
            type="number"
            value={certification.data?.carbonCredit}
            onChange={(value) => handleFieldChange('carbonCredit', parseInt(value))}
            error={errors.carbonCredit}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <DatePickerField
            label="Base Year Start"
            value={certification.data?.baseYear?.startDate}
            onChange={(date) => handleFieldChange('baseYear.startDate', date)}
            error={errors['baseYear.startDate']}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <DatePickerField
            label="Base Year End"
            value={certification.data?.baseYear?.endDate}
            onChange={(date) => handleFieldChange('baseYear.endDate', date)}
            error={errors['baseYear.endDate']}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CarbonInformation; 