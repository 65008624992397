import React from 'react';
import { Grid, Typography } from '@mui/material';
import OwnersList from './OwnersList';
import PropertyDetails from './PropertyDetails';
import AddressFields from './AddressFields';

const PropertyInformation = ({
  certification,
  handleFieldChange,
  handleDeleteOwner,
  handleAddOwner,
  errors,
}) => {
  return (
    <Grid item xs={12} style={{ border: '1px solid #ccc', margin: '30px 0 0 25px', padding: '20px', borderRadius: '8px' }}>
      <Typography variant="h6" gutterBottom>Property Information</Typography>
      
      <Grid item xs={12}>
        <OwnersList
          owners={certification.data?.owners}
          handleFieldChange={handleFieldChange}
          handleDeleteOwner={handleDeleteOwner}
          handleAddOwner={handleAddOwner}
          errors={errors}
        />
      </Grid>

      <Grid item xs={12}>
        <PropertyDetails
          certification={certification}
          handleFieldChange={handleFieldChange}
          errors={errors}
        />
      </Grid>

      <Grid item xs={12} style={{ marginTop: '16px' }}>
        <AddressFields
          address={certification.data?.address}
          handleFieldChange={handleFieldChange}
          errors={errors}
        />
      </Grid>
    </Grid>
  );
};

export default PropertyInformation; 