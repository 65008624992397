import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { format } from 'date-fns';

const TransactionsSection = ({
  certification,
}) => {
  const transactions = certification.data?.transactions || [];

  return (
    <Grid item xs={12} style={{ border: '1px solid #ccc', margin: '15px 0 0 25px', padding: '20px', borderRadius: '8px' }}>
      <Typography variant="h6" gutterBottom>Transactions</Typography>
      
      {transactions.map((transaction, index) => (
        <Box key={index} mb={2} p={2} border="1px solid #eee" borderRadius="4px">
          <Typography> <strong>Date:</strong> {transaction.date ? format(transaction.date?.toDate(), 'MM/dd/yyyy - HH:mm:ss') : ''}</Typography>
          <Typography><strong>Key:</strong> {transaction.key}</Typography>
          <Typography><strong>Type:</strong> {transaction.type}</Typography>
          <Typography><strong>Description:</strong> {transaction.description}</Typography>
          <Typography><strong>Created by:</strong> {transaction.createdBy?.email} - {transaction.createdBy?.uid}</Typography>
          {transaction.offset && (
            <Typography><strong>Offset:</strong> {transaction.offset}</Typography>
          )}
          {transaction.buyer && (
            <Box mt={1}>
              <Typography variant="subtitle2">Buyer:</Typography>
              <Typography><strong>Name:</strong> {transaction.buyer.name}</Typography>
              <Typography><strong>Document:</strong> {transaction.buyer.docId}</Typography>
            </Box>
          )}
        </Box>
      ))}
    </Grid>
  );
};

export default TransactionsSection; 