import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Box,
  Pagination,
  Tooltip,
  TablePagination
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { certificationApi } from '../../../config/firebase';
import { formatDocId } from '../../../utils/utils';
import { toast } from 'react-toastify';

const columns = [
  {id: "userKey", label: "Usuário", minWidth: 170, align: "center"},
  {id: "creationCode", label: "Código de Criação", minWidth: 100, align: "center"},
  {
    id: "baseYear",
    label: "Ano Base",
    minWidth: 170,
    align: "center",
    format: (value) => `${value.startDate} - ${value.endDate}`
  },
  {
    id: "carbonCredit",
    label: "Créditos de Carbono",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("pt-BR"),
  },
  {
    id: "status",
    label: "Situação",
    minWidth: 170,
    align: "center",
    format: (value) => value?.custody ? `Custodiado em ${value.custody}` : "Não Custodiado"
  },
];

const ROWS_PER_PAGE = 10;

const CertificationsList = () => {
  const [certifications, setCertifications] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  const [totalCertifications, setTotalCertifications] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCertification, setSelectedCertification] = useState(null);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [page, setPage] = useState(0);

  const fetchCertifications = async () => {
    try {
      setIsLoading(true);
      const result = await certificationApi.get();
      if (result.data?.data) {
        setCertifications(result.data.data);
        setTotalCertifications(result.data.data.length);
      }
    } catch (error) {
      console.error("Error fetching certifications:", error);
      toast.error("Erro ao carregar certificações. Por favor, tente novamente.");
      setCertifications([]);
      setTotalCertifications(0);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCertifications();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  /**
   * Details Dialog
   */
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleClickOpen = (row) => {
    setSelectedRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRow(null);
  };

  const renderCertificationDetails = (row) => {
    // Calculate total offset from all compensations
    const totalOffset = row.compensations.reduce((sum, compensation) => sum + compensation.offset, 0);

    return (
      <div>
        <Typography><strong>Ano Base:</strong> {`${row.baseYear.startDate} - ${row.baseYear.endDate}`}</Typography>
        <Typography><strong>Localidade:</strong> {row.address.state} / {row.address.country}</Typography>
        <Typography><strong>Créditos:</strong> {row.carbonCredit.toLocaleString("pt-BR")} CCO2</Typography>
        <Typography><strong>Créditos Compensados:</strong> {totalOffset.toLocaleString("pt-BR")} CCO2</Typography>
        <Typography><strong>Situação:</strong> {row.status?.custody ? `Custodiado em ${row.status.custody}` : "Não Custodiado"}</Typography>
        <Typography><strong>Usuário:</strong> {row.userKey}</Typography>
        <Typography variant="h6" sx={{marginTop: 2, marginBottom: 2}}>TRANSAÇÕES</Typography>
        {row.transactions?.map(renderTransactionCard) || []}
        <Typography variant="h6" sx={{marginTop: 2, marginBottom: 2}}>COMPENSAÇÕES</Typography>
        {row.compensations?.map(renderCompensationCard) || []}
      </div>
    );
  }

  const renderTransactionCard = (transaction) => {
    const cardStyles = {
      marginBottom: 2,
      backgroundColor: "rgba(241,241,241,0.42)",
    };

    switch (transaction.type) {
      case "CREATION":
        return (
          <Card key={transaction.key} sx={cardStyles}>
            <CardContent>
              <Typography><strong>Criação do Certificado</strong></Typography>
              <Typography><strong>Data:</strong> {transaction.date || 'Data não disponível'}</Typography>
              <Typography><strong>Chave:</strong> {transaction.key}</Typography>
              <Typography><strong>Descrição:</strong> {transaction.description}</Typography>
            </CardContent>
          </Card>
        );
      case "CUSTODY":
        return (
          <Card key={transaction.key} sx={cardStyles}>
            <CardContent>
              <Typography><strong>Custódia</strong></Typography>
              <Typography><strong>Data:</strong> {transaction.date || 'Data não disponível'}</Typography>
              <Typography><strong>Chave:</strong> {transaction.key}</Typography>
              <Typography><strong>Descrição:</strong> {transaction.description}</Typography>
            </CardContent>
          </Card>
        );
      default:
        return null;
    }
  };

  const renderCompensationCard = (compensation) => {
    const cardStyles = {
      marginBottom: 2,
      backgroundColor: "rgba(241,241,241,0.42)",
    };
    
    return (
      <Card key={compensation.key} sx={cardStyles}>
        <CardContent>
          <Typography><strong>Compensação</strong></Typography>
          <Typography><strong>Data:</strong> {compensation.date || 'Data não disponível'}</Typography>
          <Typography><strong>Chave:</strong> {compensation.key}</Typography>
          <Typography><strong>Quantidade:</strong> {compensation.offset} CCO2</Typography>
          <Typography><strong>Nome:</strong> {compensation.buyer.fullName}</Typography>
          <Typography><strong>{compensation.buyer.docType}:</strong> {formatDocId(compensation.buyer.docType, compensation.buyer.docId)}</Typography>
          <Typography><strong>Descrição:</strong> {compensation.description}</Typography>
        </CardContent>
      </Card>
    );
  };

  return (
    <Paper sx={{width: "100%", overflow: "hidden"}} elevation={2} className="background-transp">
      <TableContainer sx={{maxHeight: 590}}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{minWidth: column.minWidth}}>
                  {column.label}
                </TableCell>
              ))}
              <TableCell key="actions" align="center" style={{minWidth: 50}}>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={columns.length + 1} align="center" sx={{ py: 3 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress />
                  </Box>
                </TableCell>
              </TableRow>
            ) : certifications.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length + 1} align="center" sx={{ py: 3 }}>
                  Nenhum certificado encontrado.
                </TableCell>
              </TableRow>
            ) : (
              certifications
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.creationCode}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {value == null ? '' : (
                            typeof value === 'object' ? (
                              column.format ? column.format(value) : JSON.stringify(value)
                            ) : (
                              column.format ? column.format(value) : value
                            )
                          )}
                        </TableCell>
                      );
                    })}
                    <TableCell>
                      <Tooltip title="Detalhes do Certificado">
                        <IconButton color="gray" onClick={() => handleClickOpen(row)}>
                          <InfoIcon/>
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[ROWS_PER_PAGE]}
        component="div"
        count={totalCertifications}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={"Resultados por página:"}
      />
      <Dialog open={open} onClose={handleClose} PaperProps={{sx: {borderRadius: 4}}}>
        <DialogTitle>Detalhes do Certificado</DialogTitle>
        <DialogContent>{selectedRow && renderCertificationDetails(selectedRow)}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}

export default CertificationsList;
