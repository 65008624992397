import React, { useEffect } from 'react';
import { TextField as MuiTextField } from '@mui/material';

const TextField = ({
  value = '',
  onChange,
  error,
  ...props
}) => {

  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <MuiTextField
      {...props}
      value={value}
      onChange={handleChange}
      error={Boolean(error)}
      helperText={error}
    />
  );
};

export default TextField; 