import React from 'react';
import { Grid, FormControl, InputLabel, Select, MenuItem, TextField } from '@mui/material';
import { formatDocument } from '../../utils/formatters';

const DocumentField = ({
  document,
  prefix,
  handleFieldChange,
  errors,
  lastFieldXs = 6
}) => {
  return (
    <>
      <Grid item xs={12} sm={6} lg={2}>
        <FormControl fullWidth>
          <InputLabel>Document Type</InputLabel>
          <Select
            value={document.docType || 'CPF'}
            label="Document Type"
            onChange={(e) => {
              handleFieldChange(`${prefix}.docId`, '');
              handleFieldChange(`${prefix}.docType`, e.target.value);
            }}
          >
            <MenuItem value="CPF">CPF</MenuItem>
            <MenuItem value="CNPJ">CNPJ</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={lastFieldXs}>
        <TextField
          fullWidth
          required
          label="Document ID"
          value={formatDocument(document.docId, document.docType) || ''}
          onChange={(e) => {
            const formattedValue = formatDocument(e.target.value, document.docType);
            handleFieldChange(`${prefix}.docId`, formattedValue);
          }}
          error={Boolean(errors[`${prefix}.docId`])}
          helperText={errors[`${prefix}.docId`] || `Format: ${document.docType === 'CPF' ? '000.000.000-00' : '00.000.000/0000-00'}`}
          inputProps={{
            maxLength: document.docType === 'CPF' ? 14 : 18,
            inputMode: 'numeric',
            pattern: '[0-9]*'
          }}
        />
      </Grid>
    </>
  );
};

export default DocumentField; 