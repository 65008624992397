import React, { useState } from 'react';
import { Box, Typography, Grid, Button, Menu, MenuItem } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';

const StatusSection = ({
  certification,
  handleStatusChange,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCustodyTransfer = (newCustody) => {
    handleStatusChange('status.custody', newCustody);
    handleClose();
  };

  const canChangeStatus = certification.data?.status?.custody === 'ABCARBON';

  return (
    <Grid item xs={12} style={{ border: '1px solid #ccc', margin: '15px 0 0 25px', padding: '20px', borderRadius: '8px' }}>
      <Typography variant="h6" gutterBottom>Status</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography><strong>Custody:</strong> {certification.data?.status?.custody || 'Not set'}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Box display="flex" alignItems="center" gap={2}>
            {canChangeStatus && certification.id && (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  endIcon={<KeyboardArrowDown />}
                  onClick={handleClick}
                >
                  Transfer Custody
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem onClick={() => handleCustodyTransfer('CCO2 Global LLC')}>
                    CCO2 Global LLC
                  </MenuItem>
                </Menu>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StatusSection; 