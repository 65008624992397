import { cpf, cnpj } from 'cpf-cnpj-validator';
import { PHONE_FORMATS } from './constants';

export const formatDocument = (value, type) => {
  const cleanValue = value.replace(/[^0-9]/g, '');
  
  if (type === 'CPF') {
    return cleanValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
  }
  return cleanValue.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
};

export const formatPhoneNumber = (phone, countryCode) => {
  if (!phone) return '';
  const cleaned = phone.replace(/\D/g, '');
  const formatter = PHONE_FORMATS[countryCode] || PHONE_FORMATS.BR;
  return formatter.mask(cleaned);
};

export const formatCAR = (car) => {
  if (!car) return '';
  const cleaned = car.replace(/[^a-zA-Z0-9]/g, '');
  if (cleaned.length === 42) {
    return `${cleaned.slice(0, 2)}-${cleaned.slice(2, 9)}-${cleaned.slice(9, 13)}.${cleaned.slice(13, 17)}.${cleaned.slice(17, 21)}.${cleaned.slice(21, 25)}.${cleaned.slice(25, 29)}.${cleaned.slice(29, 33)}.${cleaned.slice(33, 37)}.${cleaned.slice(37)}`;
  }
  return car;
}; 