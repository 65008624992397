import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

const ReCAPTCHAComponent = ({ onChange }) => {
  return (
    <ReCAPTCHA
      sitekey="6LcK3w4qAAAAAE5TbtPJ4g06c-gnVlLmmzx41biN" // Replace with your Site Key
      onChange={onChange}
    />
  );
};

export default ReCAPTCHAComponent;
