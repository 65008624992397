import { cpf, cnpj } from 'cpf-cnpj-validator';

export const isValidEmail = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const isValidDocument = (value, type) => {
  const cleanDoc = value.replace(/\D/g, '');
  if (type === 'CPF') {
    return cleanDoc.length === 11 && cpf.isValid(cleanDoc);
  }
  return cleanDoc.length === 14 && cnpj.isValid(cleanDoc);
};

export const isValidMD5 = (hash) => /^[a-f0-9]{32}$/i.test(hash);

export const isValidSHA1 = (hash) => /^[a-f0-9]{40}$/i.test(hash);

export const validateCPF = (value) => {
  const cleanDoc = value.replace(/\D/g, '');
  return cpf.isValid(cleanDoc);
};

export const validateCNPJ = (value) => {
  const cleanDoc = value.replace(/\D/g, '');
  return cnpj.isValid(cleanDoc);
};

// Add other validation functions... 