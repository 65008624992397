import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
} from '@mui/material';
import RequestInformation from './components/RequestInformation';
import PropertyInformation from './components/PropertyInformation';
import CarbonInformation from './components/CarbonInformation';
import RegistrationInfo from './components/RegistrationInfo';
import StatusSection from './components/StatusSection';
import CompensationSection from './components/CompensationSection';
import TransactionsSection from './components/TransactionsSection';
import { useFormValidation } from './hooks/useFormValidation';
import { Timestamp } from 'firebase/firestore';
import md5 from 'md5';

const CertificationDialog = ({
  user,
  open,
  onClose,
  certification,
  handleFieldChange,
  handleAddOwner,
  handleDeleteOwner,
  handleSave,
}) => {
  const { errors, setErrors, validateAllFields, validate } = useFormValidation(certification);

  const handleOnClose = () => {
    onClose();
    setErrors({});
  };

  const handleFieldChangeAndValidate = (field, value) => {
    handleFieldChange(field, value);
    validate(field, value);
  };

  const handleAddCompensation = (compensation) => {
    const compensations = certification.data?.compensations || [];
    handleFieldChange('compensations', [...compensations, compensation]);
  };

  const handleDeleteCompensation = (index, key) => {
    const compensations = certification.data?.compensations || [];
    handleFieldChange('compensations', compensations.filter((_, i) => i !== index));
    
    const transactions = certification.data?.transactions || [];
    handleFieldChange('transactions', transactions.filter((transaction) => transaction.key !== key));
  };

  const generateTransactionKey = (certificationKey, timestamp) => {
    return md5(`${certificationKey}${timestamp}`);
  };

  const addTransaction = (type, description, additionalData = {}) => {
    addTransactionWithKey(null, type, description, additionalData);
  }

  const addTransactionWithKey = (key, type, description, additionalData = {}) => {
    const now = Timestamp.now();
    const certificationKey = certification.id || certification.data.certificationKey;
    const transactionKey = key || generateTransactionKey(certificationKey, now.toDate().toISOString());

    const transaction = {
      date: now,
      description,
      type,
      key: transactionKey,
      createdBy: {
        email: user.email,
        uid: user.uid
      },
      ...additionalData
    };

    const transactions = certification.data?.transactions || [];
    handleFieldChange('transactions', [...transactions, transaction]);
  };

  const handleStatusChange = (field, value) => {
    if (field === 'status.custody') {
      handleFieldChange(field, value);
      if (value === 'CCO2 Global LLC') {
        addTransaction('CUSTODY', 'New custody: ' + value + '.');
      }
    }
  };

  const handleSaveWithValidationAndTransaction = async () => {
    if (validateAllFields()) {
      // Add creation transaction if this is a new certification
      if (!certification.id) {
        addTransaction('CREATION', 'Certification created.');
      }
      handleSave();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      disableEnforceFocus
      disableAutoFocus
      aria-labelledby="certification-dialog-title"
    >
      <DialogTitle id="certification-dialog-title">
        {certification.id ? 'Edit Certification' : 'New Certification'}
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={3}>
          <PropertyInformation
            certification={certification}
            handleFieldChange={handleFieldChangeAndValidate}
            handleDeleteOwner={handleDeleteOwner}
            handleAddOwner={handleAddOwner}
            errors={errors}
          />

          <RequestInformation
            certification={certification}
            handleFieldChange={handleFieldChangeAndValidate}
            errors={errors}
          />

          <CarbonInformation
            certification={certification}
            handleFieldChange={handleFieldChangeAndValidate}
            errors={errors}
          />

          <RegistrationInfo
            certification={certification}
            handleFieldChange={handleFieldChangeAndValidate}
            errors={errors}
          />

          <StatusSection
            certification={certification}
            handleStatusChange={handleStatusChange}
            errors={errors}
          />

          <CompensationSection
            certification={certification}
            handleFieldChange={handleFieldChangeAndValidate}
            handleDeleteCompensation={handleDeleteCompensation}
            handleAddCompensation={handleAddCompensation}
            addTransactionWithKey={addTransactionWithKey}
            errors={errors}
          />

          <TransactionsSection
            certification={certification}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnClose}>Cancel</Button>
        <Button
          onClick={handleSaveWithValidationAndTransaction}
          variant="contained"
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CertificationDialog; 