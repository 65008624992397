import "./App.scss";

import ReactDOM from "react-dom";
import {BrowserRouter, Route, Routes, useLocation, Navigate} from "react-router-dom";
import Layout from "./components/layout";
import Home from "./components/pages/home/home";
import Certifications from "./components/pages/certifications/certifications";
import Conference from "./components/pages/conference/conference";
import Enroll from "./components/pages/enroll/enroll";
import AdminDashboard from "./components/pages/admin/admin-dashboard";
import Login from "./components/pages/login/login";
import ProtectedRoute from "./components/security/protected-route";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./config/firebase";
import { UserRoleProvider } from './contexts/UserRoleContext';

export default function App() {
  const [user] = useAuthState(auth);

  return (
    <UserRoleProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout/>}>
            <Route index element={<Home/>}/>
            <Route path="certificados" element={<Certifications/>}/>
            <Route path="conferencia" element={<Conference/>}/>
            <Route path="associe-se" element={<Enroll/>}/>
          </Route>
          <Route path="/login" element={user ? <Navigate to="/admin" /> : <Login/>} />
          <Route
            path="/admin/*"
            element={
              <ProtectedRoute>
                <AdminDashboard />
              </ProtectedRoute>
            }
          />
        </Routes>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <WhatsAppButton />
      </BrowserRouter>
    </UserRoleProvider>
  );
}

function WhatsAppButton() {
  const location = useLocation();
  const hidePaths = ["/login", "/admin"];

  return !hidePaths.some(path => location.pathname.startsWith(path)) ? (
    <a
      id="whatsAppButton"
      href="https://api.whatsapp.com/send?phone=6140404241&text=Olá, ABCARBON!"
      className="float floating-button"
      target="_blank"
      rel="noopener noreferrer"
    >
      <i className="fa fa-whatsapp my-float"></i>
    </a>
  ) : null;
}

ReactDOM.render(<App/>, document.getElementById("root"));
