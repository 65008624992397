import React from 'react';
import { Box, Typography, Button, List, ListItem, Grid, IconButton } from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import DocumentField from './FormFields/DocumentField';
import CustomTextField from './FormFields/TextField';
import { generateMD5Hash } from '../utils/hashs';
import { Timestamp } from 'firebase/firestore';
import { format } from 'date-fns';

const CompensationSection = ({
  certification,
  handleFieldChange,
  handleDeleteCompensation,
  handleAddCompensation,
  addTransactionWithKey,
  errors,
}) => {
  const handleAddCompensationWithValidation = () => {
    const now = Timestamp.now();
    const key = generateMD5Hash(certification.id + now.toDate().toISOString());
    handleAddCompensation({
      date: now,
      key: key,
      offset: '',
      description: '',
      buyer: { fullName: '', docId: '', docType: 'CPF' }
    });
    addTransactionWithKey(key, 'COMPENSATION', 'Credits compensated.', {
      date: now,
    });
  };

  return (
    <Grid item xs={12} style={{ border: '1px solid #ccc', margin: '15px 0 0 25px', padding: '20px', borderRadius: '8px' }}>
      <Typography variant="h6" gutterBottom>Compensations</Typography>
      <Box mb={3}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle1" gutterBottom>Compensations List</Typography>
          {certification.id && certification.data?.status?.custody !== 'ABCARBON' && (
            <Button
              startIcon={<Add />}
              onClick={handleAddCompensationWithValidation}
            >
              Add Compensation
            </Button>
          )}
        </Box>
        <List>
          {certification.data?.compensations?.map((compensation, index) => (
            <ListItem key={index}>
              <Grid container spacing={2} style={{ border: '1px solid #ccc', marginTop: '2px', padding: '20px', borderRadius: '8px' }}>
                <Grid item xs={12} md={4} lg={3} style={{padding: 0}}>
                  <Typography variant="caption" sx={{ ml: 2 }}>
                    <strong>Date:</strong> {compensation.date ? format(compensation.date?.toDate(), 'MM/dd/yyyy - HH:mm:ss') : ''}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8} lg={9} style={{padding: 0}}>
                  <Typography variant="caption" sx={{ ml: {xs: 2, md: 0 } }}>
                    <strong>Key:</strong> {compensation.key}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} lg={4}>
                  <CustomTextField
                    fullWidth
                    label="Name"
                    value={compensation.buyer.fullName}
                    onChange={(value) => handleFieldChange(`compensations.${index}.buyer.fullName`, value)}
                    error={errors[`compensations.${index}.buyer.fullName`]}
                    required
                  />
                </Grid>

                <DocumentField
                  document={compensation.buyer}
                  prefix={`compensations.${index}.buyer`}
                  handleFieldChange={handleFieldChange}
                  errors={errors}
                />

                <Grid item xs={12} md={9}>
                  <CustomTextField
                    fullWidth
                    label="Description"
                    value={compensation.description}
                    onChange={(value) => handleFieldChange(`compensations.${index}.description`, value)}
                    error={errors[`compensations.${index}.description`]}
                    required
                  />
                </Grid>

                <Grid item xs={8} md={2}>
                  <CustomTextField
                    fullWidth
                    label="Offset"
                    type="number"
                    value={compensation.offset}
                    onChange={(value) => handleFieldChange(`compensations.${index}.offset`, value)}
                    error={errors[`compensations.${index}.offset`]}
                    required
                  />
                </Grid>

                <Grid item xs={1}>
                  <IconButton onClick={() => handleDeleteCompensation(index, compensation.key)}>
                    <Delete />
                  </IconButton>
                </Grid>
              </Grid>
            </ListItem>
          ))}
        </List>
        {certification.data?.status?.custody === 'ABCARBON' && (
          <Typography variant="caption" sx={{ mt: 1, ml: 2 }}>
            Compensations are only available for certifications already created and custodied.
          </Typography>
        )}
      </Box>
    </Grid>
  );
};

export default CompensationSection; 