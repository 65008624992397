import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Timestamp } from 'firebase/firestore';

const DatePickerField = ({
  label,
  value,
  onChange,
  error,
  required = false,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label={label}
        required={required}
        value={value ? new Date(value.seconds * 1000) : null}
        onChange={(date) => {
          if (date) {
            onChange(Timestamp.fromDate(date));
          } else {
            onChange(null);
          }
        }}
        slotProps={{
          textField: {
            fullWidth: true,
            required: required,
            error: Boolean(error),
            helperText: error,
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default DatePickerField; 