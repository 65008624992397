// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {getAuth} from "firebase/auth";
import {getFirestore, connectFirestoreEmulator} from "firebase/firestore";
import {
  connectFunctionsEmulator, 
  getFunctions, 
  httpsCallable
} from "firebase/functions";
import {getStorage} from "firebase/storage";
import config from './config';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase with error handling
let app;
try {
  app = initializeApp(firebaseConfig);
  console.log("Firebase initialized successfully");
} catch (error) {
  console.error("Error initializing Firebase:", error);
}

// Initialize services with error handling
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app, 'us-central1');
export const analytics = typeof window !== 'undefined' 
  ? (() => {
      try {
        return getAnalytics(app);
      } catch (error) {
        console.warn('Analytics initialization failed:', error);
        return null;
      }
    })()
  : null;

// Check environment and connect to emulators if needed
console.log("config.useEmulators", config.useEmulators);
if (config.useEmulators) {
  console.log("Connecting to Firestore emulator...");
  connectFirestoreEmulator(db, config.emulatorHost, config.firestoreEmulatorPort);

  console.log("Connecting to Functions emulator...");
  connectFunctionsEmulator(functions, config.emulatorHost, config.functionsEmulatorPort);
}

// Firebase Functions
export const certificationApi = {
  get: httpsCallable(functions, 'certificationApi-get')
};

export const compensationApi = {
  get: httpsCallable(functions, 'compensationApi-get')
};

export const userApi = {
  create: httpsCallable(functions, 'userApi-create'),
  update: httpsCallable(functions, 'userApi-update'),
  delete: httpsCallable(functions, 'userApi-delete')
};
