import React from 'react';
import { Grid, Typography } from '@mui/material';
import TextField from './FormFields/TextField';
import DatePickerField from './FormFields/DatePickerField';

const RegistrationInfo = ({
  certification,
  handleFieldChange,
  errors,
}) => {
  return (
    <Grid item xs={12} style={{ border: '1px solid #ccc', margin: '15px 0 0 25px', padding: '20px', borderRadius: '8px' }}>
      <Typography variant="h6" gutterBottom>Registration Information</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <DatePickerField
            label="Registration Date"
            required
            value={certification.data?.registrationDate}
            onChange={(date) => handleFieldChange('registrationDate', date)}
            error={errors.registrationDate}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            required
            label="User Key"
            value={certification.data?.userKey || ''}
            onChange={(value) => handleFieldChange('userKey', value)}
            error={errors.userKey}
            disabled={Boolean(certification.id)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            required
            label="Certification Key"
            value={certification.id || certification.data?.certificationKey || ''}
            onChange={(value) => handleFieldChange('certificationKey', value)}
            error={errors.certificationKey}
            disabled={Boolean(certification.id)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            required
            label="Creation Transaction Code"
            value={certification.data?.creationCode || ''}
            onChange={(value) => handleFieldChange('creationCode', value)}
            error={errors.creationCode}
            disabled={Boolean(certification.id)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RegistrationInfo; 