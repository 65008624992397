export const COUNTRY_CODES = [
  { code: 'US', dialCode: '+1', flagCode: 'US', name: 'United States' },
  { code: 'GB', dialCode: '+44', flagCode: 'GB', name: 'United Kingdom' },
  { code: 'BR', dialCode: '+55', flagCode: 'BR', name: 'Brazil' },
];

export const PHONE_FORMATS = {
  BR: {
    mask: (cleaned) => {
      if (!cleaned) return '';
      // If area code is not complete, show just the opening parenthesis.
      if (cleaned.length < 3) return `(${cleaned}`;
  
      const area = cleaned.slice(0, 2);
      const numberPart = cleaned.slice(2);
  
      // If the number part is still short (up to 4 digits), don't add any hyphen.
      if (numberPart.length <= 4) {
        return `(${area}) ${numberPart}`;
      }
  
      // For a total of 10 digits (2 for area code + 8 for number), use a 4-4 split.
      if (cleaned.length <= 10) {
        return `(${area}) ${numberPart.slice(0, 4)}${numberPart.length > 4 ? '-' + numberPart.slice(4) : ''}`;
      }
  
      // Otherwise, assume a mobile number with 11 digits (2 for area code + 9 for number)
      return `(${area}) ${numberPart.slice(0, 5)}-${numberPart.slice(5, 9)}`;
    },
    // The regex now accepts either 4 or 5 digits for the prefix:
    pattern: /^\(\d{2}\) (\d{4}-?\d{4}|\d{5}-?\d{4})$/,
    example: '(51) 99999-9999 or (51) 9999-9999'
  },
  US: {
    mask: (cleaned) => {
      if (!cleaned) return '';
      if (cleaned.length < 4) {
        // While typing the area code, just show the opening parenthesis.
        return `(${cleaned}`;
      }
      if (cleaned.length < 7) {
        // Once we have 3 digits for the area code, close the parenthesis and add a space.
        return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3)}`;
      }
      // Once we have more than 6 digits, insert a hyphen after the next 3 digits.
      return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6, 10)}`;
    },
    pattern: /^\(\d{3}\) \d{3}-\d{4}$/,
    example: '(212) 555-4567'
  },
  GB: {
    mask: (cleaned) => {
      if (cleaned.length <= 3) return cleaned;
      if (cleaned.startsWith('7')) {
        if (cleaned.length <= 4) return cleaned;
        return `${cleaned.slice(0, 4)} ${cleaned.slice(4)}`;
      }
      if (cleaned.startsWith('2')) {
        if (cleaned.length <= 2) return cleaned;
        if (cleaned.length <= 6) return `${cleaned.slice(0, 2)} ${cleaned.slice(2)}`;
        return `${cleaned.slice(0, 2)} ${cleaned.slice(2, 6)} ${cleaned.slice(6)}`;
      }
      if (cleaned.length <= 3) return cleaned;
      if (cleaned.length <= 6) return `${cleaned.slice(0, 3)} ${cleaned.slice(3)}`;
      return `${cleaned.slice(0, 3)} ${cleaned.slice(3, 6)} ${cleaned.slice(6)}`;
    },
    pattern: /^((20\s\d{4}\s\d{4})|(7\d{3}\s\d{6})|(\d{3}\s\d{3}\s\d{4}))$/,
    example: '7911 123456 (mobile) or 20 1234 5678 (London)'
  }
}; 